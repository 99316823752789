import { useGlobalStore } from '@/stores'
import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'tabelleVarie',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Varie',
    CODVOCEMENU: '000038',
    CODPERMESSO: 'TABELLE_SP',
}

export const SETTINGS = Object.freeze({
    TABELLE_VARIE: {
        TITLE: 'Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Varie',
        PATH: 'tabelleVarie/:year?/:month?/:attualeCheck?/:allValidate?/',
        NAME: 'TabelleVarie',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICE_DESCRIZIONE: {
        TITLE: 'Codice descrizione - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codice descrizione VCODES',
        PATH: 'codiceDescrizione/:codTable?/',

        NAME: 'codiceDescrizione',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICE_DESCRIZIONE_SINGOLA: {
        TITLE: 'Codice descrizione singola - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codice descrizione SINGOLA',
        PATH: 'codiceDescrizioneSingole/:codTable?/',

        NAME: 'codiceDescrizioneSingola',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_COMUNI: {
        TITLE: 'Comuni - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Comuni',
        PATH: 'comuni',
        NAME: 'comuni',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CNEL: {
        TITLE: 'Codici contratto CNEL - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici contratto CNEL',
        PATH: 'codiciContrattoCNEL',
        NAME: 'codiciContrattoCNEL',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_UNIEMENS: {
        TITLE: 'Uniemens - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Uniemens',
        PATH: 'uniemens',
        NAME: 'uniemens',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_COTRATTO_INPS: {
        TITLE: 'Codici contratto INPS - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici contratto INPS ',
        PATH: 'codContrINPS',
        NAME: 'codContrINPS',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICI_ESAZIONI: {
        TITLE: 'Codici contratto INAIL - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'INAIL: Codici esazione',
        PATH: 'codiciEsazione',
        NAME: 'codiciEsazione',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_MAGGIORAZIONI_SPETTANTI: {
        TITLE: 'Maggiorazioni spettanti - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Maggiorazioni spettanti',
        PATH: 'maggiorazioniSpettanti',
        NAME: 'maggiorazioniSpettanti',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_PROVINCE: {
        TITLE: 'Province - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Province',
        PATH: 'province',
        NAME: 'province',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_QUALIFICHE_INPS: {
        TITLE: 'Qualifiche INPS - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Qualifiche INPS',
        PATH: 'qualifiInps',
        NAME: 'qualifiInps',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_UNIMARE: {
        TITLE: 'Unimare - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Unimare',
        PATH: 'Unimare',
        NAME: 'Unimare',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_UNILAV: {
        TITLE: 'Unilav - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Unilav',
        PATH: 'Unilav',
        NAME: 'Unilav',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_VOCI_DI_TARIFFA: {
        TITLE: 'Voci di tariffa - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'INAIL: Voci di tariffa',
        PATH: 'vociDiTariffa',
        NAME: 'vociDiTariffa',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_TIPO_SCONTO: {
        TITLE: 'Tipo sconto - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'INAIL: Tipo sconto',
        PATH: 'tipoSconto',
        NAME: 'tipoSconto',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CLASSI_DI_RISCHIO_ARTIGIANI: {
        TITLE: 'Classi di richio artigiani - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'INAIL: Classi di rischio artigiani',
        PATH: 'classiDiRischioArtigiani',
        NAME: 'classiDiRischioArtigiani',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CAAF: {
        TITLE: 'Caaf - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Caaf',
        PATH: 'caaf',
        NAME: 'caaf',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICI_CONTRATTO_CO: {
        TITLE: 'Codici contratto CO - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici contratto CO',
        PATH: 'codiciContrattoCO',
        NAME: 'codiciContrattoCO',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_COSTANTI: {
        TITLE: 'Costanti - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Costanti',
        PATH: 'costanti',
        NAME: 'costanti',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICI_TRIBUTO: {
        TITLE: 'Codici tributo - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici tributo',
        PATH: 'codiciTributo',
        NAME: 'codiciTributo',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CODICI_OUTPUT: {
        TITLE: 'Codici output - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Codici output',
        PATH: 'codiciOutput',
        NAME: 'codiciOutput',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_TIPO_RETRIBUZIONE: {
        TITLE: 'Tipo retribuzione particolare - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Tipo retribuzione particolare',
        PATH: 'tipoRetribuzione',
        NAME: 'tipoRetribuzione',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_BANCHE: {
        TITLE: 'Banche - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Banche',
        PATH: 'banche',
        NAME: 'banche',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_DETRAZIONI_FIGLI: {
        TITLE: 'IRPEF detrazioni figli - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'IRPEF detrazioni figli',
        PATH: 'irpefDetrazioniFigli',
        NAME: 'irpefDetrazioniFigli',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_SCAGLIONI_IMPOSTA: {
        TITLE: "IRPEF: scaglioni d'imposta - Varie - Tabelle di domino - Gestione - Paghe",
        LABEL: "IRPEF: scaglioni d'imposta",
        PATH: 'irpefScaglioni',
        NAME: 'irpefScaglioni',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_DETRAZIONI: {
        TITLE: 'IRPEF: detrazione art.13 co.1-2  - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'IRPEF: detrazione art.13 co.1-2',
        PATH: 'irpefDetrazioni',
        NAME: 'irpefDetrazioni',
        TAB: { ...tabPropsDefault },
    },

    TABELLE_IRPEF_DETRAZIONI_ART13_CO5: {
        TITLE: 'IRPEF: detrazioni art.13 co.5 - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'IRPEF: detrazioni art.13 co.5',
        PATH: 'irpefDetrazioniArt13Co5',
        NAME: 'irpefDetrazioniArt13Co5',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_DETRAZIONI_ALTRI_CARICHI: {
        TITLE: 'IRPEF: detrazioni altri carichi - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'IRPEF: detrazioni altri carichi',
        PATH: 'irpefDetrazioneAltriCarichi',
        NAME: 'IrpefDetrazioneAltriCarichi',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_DETRAZIONI_CONIUGE: {
        TITLE: 'IRPEF: detrazioni coniuge - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'IRPEF: detrazioni coniuge',
        PATH: 'irpefDetrConiuge',
        NAME: 'irpefDetrConiuge',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_DETRAZIONI_TRATTAMENTO_INTEGRATIVO: {
        TITLE: 'IRPEF: detrazioni trattamento integrativo art 1 DL3/2020 - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'IRPEF: detrazioni trattamento integrativo art 1 DL3/2020',
        PATH: 'irpefTrattIntegr',
        NAME: 'irpefTrattIntegr',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_CREDITO_IMPOSTA: {
        TITLE: "Credito d'imposta  - Varie - Tabelle di domino - Gestione - Paghe",
        LABEL: "Credito d'imposta",
        PATH: 'creditoImposta',
        NAME: 'creditoImposta',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_DATO_MESE: {
        TITLE: 'Dato mese  - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Dati mese',
        PATH: 'datiMese',
        NAME: 'DatoMese',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_VERSAMENTOF24: {
        TITLE: 'Data versamento F24 - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Data versamento F24',
        PATH: 'dataVersamentoF24',
        NAME: 'DataVersamentoF24',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_DATO_FISSO: {
        TITLE: 'Dati fissi - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Dati fissi',
        PATH: 'datoFisso',
        NAME: 'DatoFisso',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_PROPIETA_AGGIUNTIVA: {
        TITLE: 'Proprietà aggiuntiva altre entità - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Proprietà aggiuntiva altre entità',
        PATH: 'vProprieta',
        NAME: 'VProprieta',
        TAB: { ...tabPropsDefault },
    },
    FORMATO_ALIQUOTA: {
        TITLE: 'Formato sviluppo aliquota  - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Formato sviluppo aliquota',
        PATH: 'formAliquota',
        NAME: 'formAliquota',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_INTERESSI_RATEIZZAZIONE: {
        TITLE: 'Interessi rateizzazione  - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'INAIL: Interessi rateizzazione',
        PATH: 'interessiRateizzazione',
        NAME: 'interessiRateizzazione',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_INAIL_SEDI: {
        TITLE: 'INAIL: Sedi  - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'INAIL: Sedi',
        PATH: 'inailSedi',
        NAME: 'inailSedi',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_IRPEF_DETRAZIONI_ART13_C5BIS: {
        TITLE: 'INAIL: DETRAZIONI ART.13 C.5 BIS TUIR  - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'IRPEF: detrazioni art.13 c.5 bis TUIR',
        PATH: 'detrazioniArt13c5',
        NAME: 'detrazioniArt13c5',
        TAB: { ...tabPropsDefault },
    },
    VMESSAGGI: {
        TITLE: 'VMessaggi- Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Messaggi',
        PATH: 'vMessaggi',
        NAME: 'vMessaggi',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_QUALIFICHE_DIPTE: {
        TITLE: 'Qualifiche dipendente - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Qualifiche dipendente',
        PATH: 'qualificheDipte',
        NAME: 'qualificheDipte',
        TAB: { ...tabPropsDefault },
    },

    TABELLE_TIPI_RAPPORTO: {
        TITLE: 'Tipi rapporto - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Tipi rapporto',
        PATH: 'tipiRapporto',
        NAME: 'tipiRapporto',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_SITUAZIONE: {
        TITLE: 'Situazione ragzione statuto speciale - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Situazione',
        PATH: 'situazione',
        NAME: 'situazione',
        TAB: { ...tabPropsDefault },
    },
    TABELLE_SEDE_INPS: {
        TITLE: 'Sede inps - Varie - Tabelle di domino - Gestione - Paghe',
        LABEL: 'Sedi inps',
        PATH: 'sedeInps',
        NAME: 'sedeInps',
        TAB: { ...tabPropsDefault },
    },
})

const routes: any = {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.TABELLE_VARIE.PATH,
            name: SETTINGS.TABELLE_VARIE.NAME,
            component: pagheViews.tabelle.varie.dettaglio,
            meta: {
                title: SETTINGS.TABELLE_VARIE.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab.year,
                    month: () => useGlobalStore().state.periodoElab.month,
                    attualeCheck: 1,
                    allValidate: 0,
                },
            },
            props: true,

            children: [
                {
                    path: SETTINGS.TABELLE_CODICE_DESCRIZIONE.PATH,
                    name: SETTINGS.TABELLE_CODICE_DESCRIZIONE.NAME,
                    component: pagheViews.tabelle.varie.childs.codiceDescrizione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'VCODD',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },

                {
                    path: SETTINGS.TABELLE_CODICE_DESCRIZIONE_SINGOLA.PATH,
                    name: SETTINGS.TABELLE_CODICE_DESCRIZIONE_SINGOLA.NAME,
                    component: pagheViews.tabelle.varie.childs.codiceDescrizione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'VCODD',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },

                {
                    path: SETTINGS.TABELLE_COMUNI.PATH,
                    name: SETTINGS.TABELLE_COMUNI.NAME,
                    component: pagheViews.tabelle.varie.childs.comuni,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TCOMU',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CNEL.PATH,
                    name: SETTINGS.TABELLE_CNEL.NAME,
                    component: pagheViews.tabelle.varie.childs.CodiciContrattoCNEL,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TCN00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_UNIEMENS.PATH,
                    name: SETTINGS.TABELLE_UNIEMENS.NAME,
                    component: pagheViews.tabelle.varie.childs.uniemens,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TU000', //TODO: DA CAMBIARE CHIEDERE A GIACOMO
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_COTRATTO_INPS.PATH,
                    name: SETTINGS.TABELLE_COTRATTO_INPS.NAME,
                    component: pagheViews.tabelle.varie.childs.contrattoInps,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC400',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CODICI_ESAZIONI.PATH,
                    name: SETTINGS.TABELLE_CODICI_ESAZIONI.NAME,
                    component: pagheViews.tabelle.varie.childs.codiciEsazione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TD800',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CODICI_OUTPUT.PATH,
                    name: SETTINGS.TABELLE_CODICI_OUTPUT.NAME,
                    component: pagheViews.tabelle.varie.childs.codiciOutput,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TH100',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_UNILAV.PATH,
                    name: SETTINGS.TABELLE_UNILAV.NAME,
                    component: pagheViews.tabelle.varie.childs.unilav,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TCO00', //TODO: DA CAMBIARE CHIEDERE A GIACOMO
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_UNIMARE.PATH,
                    name: SETTINGS.TABELLE_UNIMARE.NAME,
                    component: pagheViews.tabelle.varie.childs.unimare,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TGM00', //TODO: DA CAMBIARE CHIEDERE A GIACOMO
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },

                {
                    path: SETTINGS.TABELLE_QUALIFICHE_INPS.PATH,
                    name: SETTINGS.TABELLE_QUALIFICHE_INPS.NAME,
                    component: pagheViews.tabelle.varie.childs.qualificheInps,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC600',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },

                {
                    path: SETTINGS.TABELLE_TIPO_SCONTO.PATH,
                    name: SETTINGS.TABELLE_TIPO_SCONTO.NAME,
                    component: pagheViews.tabelle.varie.childs.TipoSconto,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TD700',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CLASSI_DI_RISCHIO_ARTIGIANI.PATH,
                    name: SETTINGS.TABELLE_CLASSI_DI_RISCHIO_ARTIGIANI.NAME,
                    component: pagheViews.tabelle.varie.childs.classiDiRischioArtigiani,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TD500',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },

                {
                    path: SETTINGS.TABELLE_VOCI_DI_TARIFFA.PATH,
                    name: SETTINGS.TABELLE_VOCI_DI_TARIFFA.NAME,
                    component: pagheViews.tabelle.varie.childs.vociDiTariffa,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TE700',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_MAGGIORAZIONI_SPETTANTI.PATH,
                    name: SETTINGS.TABELLE_MAGGIORAZIONI_SPETTANTI.NAME,
                    component: pagheViews.tabelle.varie.childs.maggiorazioniSpettanti,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'T7A00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CAAF.PATH,
                    name: SETTINGS.TABELLE_CAAF.NAME,
                    component: pagheViews.tabelle.varie.childs.Caaf,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TB900',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CODICI_TRIBUTO.PATH,
                    name: SETTINGS.TABELLE_CODICI_TRIBUTO.NAME,
                    component: pagheViews.tabelle.varie.childs.codiciTributo,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TB500',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CODICI_CONTRATTO_CO.PATH,
                    name: SETTINGS.TABELLE_CODICI_CONTRATTO_CO.NAME,
                    component: pagheViews.tabelle.varie.childs.CodiciContrattoCO,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC900',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_COSTANTI.PATH,
                    name: SETTINGS.TABELLE_COSTANTI.NAME,
                    component: pagheViews.tabelle.varie.childs.constanti,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TKK00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_BANCHE.PATH,
                    name: SETTINGS.TABELLE_BANCHE.NAME,
                    component: pagheViews.tabelle.varie.childs.banche,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TBANC',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_PROVINCE.PATH,
                    name: SETTINGS.TABELLE_PROVINCE.NAME,
                    component: pagheViews.tabelle.varie.childs.province,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TPROV',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_DETRAZIONI_FIGLI.PATH,
                    name: SETTINGS.TABELLE_IRPEF_DETRAZIONI_FIGLI.NAME,
                    component: pagheViews.tabelle.varie.childs.IrpefDetrazioniFigli,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TID00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_DETRAZIONI_ALTRI_CARICHI.PATH,
                    name: SETTINGS.TABELLE_IRPEF_DETRAZIONI_ALTRI_CARICHI.NAME,

                    component: pagheViews.tabelle.varie.childs.IrpefDetrazioneAltriCarichi,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TIE00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_DETRAZIONI_TRATTAMENTO_INTEGRATIVO.PATH,
                    name: SETTINGS.TABELLE_IRPEF_DETRAZIONI_TRATTAMENTO_INTEGRATIVO.NAME,

                    component: pagheViews.tabelle.varie.childs.IrpefTrattIntegr,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TIL00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_TIPO_RETRIBUZIONE.PATH,
                    name: SETTINGS.TABELLE_TIPO_RETRIBUZIONE.NAME,
                    component: pagheViews.tabelle.varie.childs.tipoRetribuzione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC700',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_SCAGLIONI_IMPOSTA.PATH,
                    name: SETTINGS.TABELLE_IRPEF_SCAGLIONI_IMPOSTA.NAME,
                    component: pagheViews.tabelle.varie.childs.IrpefScaglioniImposta,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TI100',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_DETRAZIONI.PATH,
                    name: SETTINGS.TABELLE_IRPEF_DETRAZIONI.NAME,
                    component: pagheViews.tabelle.varie.childs.IrpefDetrazione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TIB00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_DETRAZIONI_CONIUGE.PATH,
                    name: SETTINGS.TABELLE_IRPEF_DETRAZIONI_CONIUGE.NAME,
                    component: pagheViews.tabelle.varie.childs.IrpefDetrazioneConiuge,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TIC00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_CREDITO_IMPOSTA.PATH,
                    name: SETTINGS.TABELLE_CREDITO_IMPOSTA.NAME,
                    component: pagheViews.tabelle.varie.childs.creditoImposta,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TJ400',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_DATO_FISSO.PATH,
                    name: SETTINGS.TABELLE_DATO_FISSO.NAME,
                    component: pagheViews.tabelle.varie.childs.datoFisso,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TJ300',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_PROPIETA_AGGIUNTIVA.PATH,
                    name: SETTINGS.TABELLE_PROPIETA_AGGIUNTIVA.NAME,
                    component: pagheViews.tabelle.varie.childs.proprietaAggiuntiva,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TPROP',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_VERSAMENTOF24.PATH,
                    name: SETTINGS.TABELLE_VERSAMENTOF24.NAME,
                    component: pagheViews.tabelle.varie.childs.dataVersamentoF24,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TM100',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_DETRAZIONI_ART13_CO5.PATH,
                    name: SETTINGS.TABELLE_IRPEF_DETRAZIONI_ART13_CO5.NAME,
                    component: pagheViews.tabelle.varie.childs.IrpefDetrazioneArt13Co5,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TIF00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_DATO_MESE.PATH,
                    name: SETTINGS.TABELLE_DATO_MESE.NAME,
                    component: pagheViews.tabelle.varie.childs.datoMese,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TM000',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.FORMATO_ALIQUOTA.PATH,
                    name: SETTINGS.FORMATO_ALIQUOTA.NAME,
                    component: pagheViews.tabelle.varie.childs.FormatoAliquota,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TF600',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_INTERESSI_RATEIZZAZIONE.PATH,
                    name: SETTINGS.TABELLE_INTERESSI_RATEIZZAZIONE.NAME,
                    component: pagheViews.tabelle.varie.childs.InailInteressi,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TD500',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_INAIL_SEDI.PATH,
                    name: SETTINGS.TABELLE_INAIL_SEDI.NAME,
                    component: pagheViews.tabelle.varie.childs.InailSedi,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TD100',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_IRPEF_DETRAZIONI_ART13_C5BIS.PATH,
                    name: SETTINGS.TABELLE_IRPEF_DETRAZIONI_ART13_C5BIS.NAME,
                    component: pagheViews.tabelle.varie.childs.IrpefDetrazioneArt13C5,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TIG00',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.VMESSAGGI.PATH,
                    name: SETTINGS.VMESSAGGI.NAME,
                    component: pagheViews.tabelle.varie.childs.messaggi,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TV999',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_QUALIFICHE_DIPTE.PATH,
                    name: SETTINGS.TABELLE_QUALIFICHE_DIPTE.NAME,
                    component: pagheViews.tabelle.varie.childs.qualificheDipte,
                    meta: {
                        group: SETTINGS.TABELLE_QUALIFICHE_DIPTE.NAME,
                        idPaginaMsg: 'TF000',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_TIPI_RAPPORTO.PATH,
                    name: SETTINGS.TABELLE_TIPI_RAPPORTO.NAME,
                    component: pagheViews.tabelle.varie.childs.tipoRapporto,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC500',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_SITUAZIONE.PATH,
                    name: SETTINGS.TABELLE_SITUAZIONE.NAME,
                    component: pagheViews.tabelle.varie.childs.situazione,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TB800',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
                {
                    path: SETTINGS.TABELLE_SEDE_INPS.PATH,
                    name: SETTINGS.TABELLE_SEDE_INPS.NAME,
                    component: pagheViews.tabelle.varie.childs.sedeInps,
                    meta: {
                        group: SETTINGS.TABELLE_VARIE.NAME,
                        idPaginaMsg: 'TC100',
                        permissions: {
                            codPermesso: 'TABELLE_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    props: true,
                },
            ],
        },
    ],
}

export default routes
