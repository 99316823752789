import { type ComputedRef, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { GLOBALS } from '@/constants'
import { useDiario } from '@/composables/diarioModal'
import { usePage } from '@/composables/page'
import pagheRoutes from '@paghe/routes'
import { ROUTES_DIPENDENTE } from '@/routes/route-paghe-dipendente' //TODO da sostituire con modul
//import { ROUTES_COLLABORATORE } from '@/routes/route-paghe-collaboratore' //TODO da sostituire con modul
import type { BaseUniemensDipteProps } from '../scripts'
import { getFieldUP } from '@paghe/views/anagrafiche/ditte/scripts'

//USATO DAI DETTAGLI
export function usePageDipteDettaglioUniemens(
    props: BaseUniemensDipteProps,
    pageStore: any,
    isCollaboratore: boolean = false,
) {
    const router = useRouter()
    const route = useRoute()
    const gestione: Gestione = 'crr'
    const isFiliale = +route.params?.dittaPadreId > 0

    const bottomBarListArchivi: FooterDropdownMenuItem[] = [
        {
            title: isCollaboratore ? 'Anagrafica collaboratore' : 'Anagrafica dipendente',
            enabled: true,
            action: () => {
                router.replace({
                    name: ROUTES_DIPENDENTE.DIPENDENTE_ANAGRAFICA.NAME,
                    params: {
                        ...props,
                        mod: GLOBALS.DATA_MODE.MOD,
                        gestione,
                    },
                })
            },
        },
        {
            title: 'Anagrafica ditta',
            enabled: true,
            action: () => {
                router.replace({
                    name: pagheRoutes.anagrafiche.ditte.SETTINGS.DITTA_ANAGRAFICA.NAME,
                    params: {
                        id: props.dittaId,
                        idPadre: 0,
                        mod: GLOBALS.DATA_MODE.MOD,
                        month: props.month,
                        year: props.year,
                        gestione,
                    },
                })
            },
        },
        {
            title: isCollaboratore ? 'Risultati collaboratore' : 'Risultati dipendente',
            enabled: true,
            action: () => {
                router.replace({
                    name: isCollaboratore
                        ? pagheRoutes.risultati.dettaglioCo.SETTINGS.COLLABORATORE_RISULTATI.NAME
                        : pagheRoutes.risultati.dettaglioDp.SETTINGS.DIPENDENTE_RISULTATI.NAME,
                    params: { ...props }, //sono identiche
                })
            },
        },
    ]

    if (props.dittaPadreId > 0) {
        bottomBarListArchivi.push({
            title: 'Anagrafica UP',
            enabled: true,
            action: () => {
                router.replace({
                    name: pagheRoutes.anagrafiche.ditte.SETTINGS.DITTA_ANAGRAFICA.NAME,
                    params: {
                        id: props.dittaId,
                        idPadre: props.dittaPadreId,
                        mod: GLOBALS.DATA_MODE.MOD,
                        month: props.month,
                        year: props.year,
                        gestione,
                    },
                })
            },
        })
    }

    const codContratto = computed(() => {
        if (isCollaboratore) return null
        return pageStore?.state?.data?.dipteAlteraDitta?.codContratto
            ? pageStore?.state?.data.dipteAlteraDitta.codContratto
            : getFieldUP(
                  pageStore?.state?.data?.dipteUnitaProduttiva?.dittaUP?.dittaUPTabellaAttivaList,
                  'dittaUPGestione',
                  'codContratto',
                  pageStore?.state?.data?.ditta?.dittaUPGestione,
                  pageStore?.state?.data?.dipteUnitaProduttiva?.dittaUP?.dittaUPGestione,
                  isFiliale,
              )
    })

    const codAliquota = computed(() =>
        pageStore?.state?.data?.dipteAlteraDitta?.codAliquota || isCollaboratore
            ? pageStore?.state?.data?.dipteAlteraDitta?.codAliquota
            : getFieldUP(
                  pageStore?.state?.data?.dipteUnitaProduttiva?.dittaUP?.dittaUPTabellaAttivaList,
                  'dittaUPGestione',
                  'codAliquota',
                  pageStore?.state?.data?.ditta?.dittaUPGestione,
                  pageStore?.state?.data?.dipteUnitaProduttiva?.dittaUP?.dittaUPGestione,
                  isFiliale,
              ),
    )

    const bottomBarListTabelle: ComputedRef<FooterDropdownMenuItem[]> = computed(() => {
        const base = [
            {
                title: codAliquota.value ? `Aliquota - ${codAliquota.value}` : 'Aliquota',
                enabled: route.params.mod === GLOBALS.DATA_MODE.MOD && !!codAliquota.value,
                action: () => {
                    router.replace({
                        name: pagheRoutes.tabelle.aliquote.SETTINGS.ALIQUOTE.NAME,
                        params: {
                            year: route.params.year,
                            month: route.params.month,
                            codice: codAliquota.value,
                            attualeCheck: 0,
                            allValidate: 0,
                        },
                    })
                },
            },
        ]
        if (!isCollaboratore) {
            base.push({
                title: codContratto.value ? `Contratto - ${codContratto.value}` : 'Contratto',
                enabled: route.params.mod === GLOBALS.DATA_MODE.MOD && !!codContratto.value,
                action: () => {
                    router.replace({
                        name: pagheRoutes.tabelle.contratto.SETTINGS.GENERALE.NAME,
                        params: {
                            year: route.params.year,
                            month: route.params.month,
                            codice: codContratto.value,
                            attualeCheck: 0,
                            allValidate: 0,
                        },
                    })
                },
            })
        }
        return base
    })

    return {
        bottomBarListArchivi,
        bottomBarListTabelle,
    }
}

export function usePageDittaDettaglioUniemens(props: BaseUniemensDipteProps, pageStore: any) {
    const router = useRouter()
    const route = useRoute()
    const gestione: Gestione = 'crr'
    const isFiliale = +route.params?.dittaPadreId > 0

    function openList(listName: string, dittaProps: any) {
        router.replace({
            name: listName,
            params: {
                year: dittaProps.year,
                month: dittaProps.month,
                day: 1,
                dittaId: dittaProps.id,
                dittaPadreId: dittaProps.idPadre || 0,
                mod: 'mod',
                gestione,
            },
        })
    }

    const bottomBarListArchivi: FooterDropdownMenuItem[] = [
        {
            title: 'Elenco dipendenti risultati',
            enabled: true,
            action: () =>
                openList(
                    pagheRoutes.risultati.dettaglioDp.SETTINGS.DIPENDENTE_LISTA.NAME,
                    route.params,
                ),
        },
        {
            title: 'Elenco dipendenti uniemens',
            enabled: true,
            action: () =>
                openList(pagheRoutes.uniemens.dipendente.SETTINGS.LISTA.NAME, route.params),
        },
        {
            title: 'Anagrafica ditta',
            enabled: true,
            action: () => {
                router.replace({
                    name: pagheRoutes.anagrafiche.ditte.SETTINGS.DITTA_ANAGRAFICA.NAME,
                    params: {
                        id: props.dittaId,
                        idPadre: 0,
                        mod: GLOBALS.DATA_MODE.MOD,
                        month: props.month,
                        year: props.year,
                        gestione,
                    },
                })
            },
        },
    ]

    if (props.dittaPadreId > 0) {
        bottomBarListArchivi.push({
            title: 'Anagrafica UP',
            enabled: true,
            action: () => {
                router.replace({
                    name: pagheRoutes.anagrafiche.ditte.SETTINGS.DITTA_ANAGRAFICA.NAME,
                    params: {
                        id: props.dittaId,
                        idPadre: props.dittaPadreId,
                        mod: GLOBALS.DATA_MODE.MOD,
                        month: props.month,
                        year: props.year,
                        gestione,
                    },
                })
            },
        })
    }

    const bottomBarListTabelle: ComputedRef<FooterDropdownMenuItem[]> = computed(() => [
        {
            title: getDittaUPGestione.value?.codAliquota
                ? `Aliquota - ${getDittaUPGestione.value?.codAliquota}`
                : 'Aliquota',
            enabled: !!getDittaUPGestione.value?.codAliquota,
            action: () => {
                router.replace({
                    name: pagheRoutes.tabelle.aliquote.SETTINGS.ALIQUOTE.NAME,
                    params: {
                        year: route.params.year,
                        month: route.params.month,
                        codice: getDittaUPGestione.value?.codAliquota,
                        attualeCheck: 0,
                        allValidate: 0,
                    },
                })
            },
        },
        {
            title: getDittaUPGestione.value?.codContratto
                ? `Contratto - ${getDittaUPGestione.value?.codContratto}`
                : 'Contratto',
            enabled: !!getDittaUPGestione.value?.codContratto,
            action: () => {
                router.replace({
                    name: pagheRoutes.tabelle.contratto.SETTINGS.GENERALE.NAME,
                    params: {
                        year: route.params.year,
                        month: route.params.month,
                        codice: getDittaUPGestione.value?.codContratto,
                        attualeCheck: 0,
                        allValidate: 0,
                    },
                })
            },
        },
    ])

    const getDittaUPGestione = computed(() => {
        if (isFiliale) {
            //inserire tabella attiva ????
            return isTableUPActive(
                pageStore?.state?.data?.dittaUPTabellaAttivaList,
                'Ditta_UPGestione',
            )
                ? pageStore?.state?.data?.dittaUPGestione
                : pageStore?.state?.data?.padre?.dittaUPGestione
        }
        return pageStore?.state?.data?.dittaUPGestione
    })

    return {
        bottomBarListArchivi,
        bottomBarListTabelle,
    }
}
//USATO NELLE CHILDS
export function usePageDittaUniemens(
    pageStore: any, //dettaglioStore
    isCollaboratore: boolean = false,
) {
    const page = usePage()
    const refDiario = reactive({
        pageName: 'DiarioUniemenDipte',
        type: isCollaboratore ? 'collaboratore' : 'dipendente',
        periodo: { periodoDiRiferimento: pageStore.getPeriodoFromParametri(true) },
    })

    const { openDiarioDipte, addDiarioToSavePayload } = useDiario(refDiario)

    const getPagePermission = computed(() => !pageStore.unlockForm || page.disableField.value)

    //watch(enableBottomButtons, val => pageStore.setToSave(val)) //NECESSARIIO PER IL DETTAGLIO

    return {
        addDiarioToSavePayload,
        openDiarioDipte,
        getPagePermission,
    }
}

//USATO NELLE CHILDS
export function usePageDipteUniemens(
    pageStore: any, //dettaglioStore
    isCollaboratore: boolean = false,
) {
    const page = usePage()
    const refDiario = reactive({
        pageName: 'DiarioUniemenDipte',
        type: isCollaboratore ? 'collaboratore' : 'dipendente',
        periodo: { periodoDiRiferimento: pageStore.getPeriodoFromParametri(true) },
    })

    const { openDiarioDipte, addDiarioToSavePayload } = useDiario(refDiario)

    const getPagePermission = computed(() => !pageStore.unlockForm || page.disableField.value)

    //watch(enableBottomButtons, val => pageStore.setToSave(val)) //NECESSARIIO PER IL DETTAGLIO

    return {
        addDiarioToSavePayload,
        openDiarioDipte,
        getPagePermission,
    }
}
