import { useGlobalStore } from '@/stores'
import contrattoViews from '@/modules/paghe/views/tabelle/contratto'
import { ContrattoEntity } from '@/modules/paghe/entities/contratto'

const tabPropsDefault: TabProps = {
    GROUP: 'Contratto',
    ICON: 'fa-regular fa-file-contract',
    TITLE: 'Contratti',
    CODVOCEMENU: '000026',
    CODPERMESSO: ['TABELLE_SP', 'TABELLE_STUDIO'], //DOVREBBE ESSERE UN ARRAY
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        ...route.params,
        codice: String(route.params?.codice),
        year: Number(route.params?.year ?? useGlobalStore().state.periodoElab!.year),
        month: Number(route.params?.month ?? useGlobalStore().state.periodoElab!.month),
        attualeCheck: Number(route.params?.attualeCheck ?? 1),
        //allValidate: Number(route.params?.allValidate ?? 0),
    }
}

export const SETTINGS = {
    ELENCO: {
        TITLE: 'Tabelle di domino - Gestione - Elenco contratti',
        LABEL: 'Elenco contratti',
        PATH: 'contratto/elenco/:year/:month/:attualeCheck',
        NAME: 'ContrattoElenco',
        TAB: { ...tabPropsDefault },
    },
    DETTAGLIO: {
        TITLE: 'Tabelle di domino - Gestione - Dettaglio contratto',
        LABEL: 'Dettaglio contratto',
        PATH: 'contratto/dettaglio/:year/:month/:attualeCheck/:codice/:mod', //codice o id ?
        NAME: 'ContrattoDettaglio',
        TAB: { ...tabPropsDefault },
    },
    //Children
    GENERALE: {
        TITLE: null,
        LABEL: 'Generale',
        PATH: 'generale',
        NAME: 'ContrattoGenerale',
        TAB: { ...tabPropsDefault, TITLE: 'Contratto Generale' },
    },
    SUDDIVISIONI: {
        TITLE: null,
        LABEL: 'Suddivisioni contr./terr.',
        PATH: 'sudd',
        NAME: 'ContrattoSuddContrTerr',
        TAB: { ...tabPropsDefault },
    },
} as const

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.ELENCO.PATH,
            name: SETTINGS.ELENCO.NAME,
            component: contrattoViews.Elenco,
            meta: {
                title: SETTINGS.ELENCO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                idPaginaMsg: 'TCO00',
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab!.year,
                    month: () => useGlobalStore().state.periodoElab!.month,
                    attualeCheck: 1,
                    //allValidate: 0,
                },
            },
            props: propsFn,
        },
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: contrattoViews.dettaglio.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                // defaultProps: {
                //     year: () => useGlobalStore().state.periodoElab!.year,
                //     month: () => useGlobalStore().state.periodoElab!.month,
                //     attualeCheck: 1,
                //     allValidate: 0,
                // },
                props: propsFn,
            },
            props: propsFn,
            children: [
                {
                    path: SETTINGS.GENERALE.PATH,
                    name: SETTINGS.GENERALE.NAME,
                    component: contrattoViews.dettaglio.childs.Generale,
                    label: SETTINGS.GENERALE.LABEL,
                    tab: SETTINGS.GENERALE.TAB,
                    meta: {
                        title: SETTINGS.GENERALE.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO01',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: 0,
                },
                {
                    path: SETTINGS.SUDDIVISIONI.PATH,
                    name: SETTINGS.SUDDIVISIONI.NAME,
                    component: contrattoViews.dettaglio.childs.SuddContrTerr,
                    label: SETTINGS.SUDDIVISIONI.LABEL,
                    tab: SETTINGS.SUDDIVISIONI.TAB,
                    entity: [ContrattoEntity.Suddivisione],
                    meta: {
                        title: SETTINGS.SUDDIVISIONI.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO02',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
            ],
        },
    ],
}
