import SociViews from '@paghe/views/anagrafiche/soci'

const tabPropsDefault: TabProps = {
    TITLE: 'Anagrafica Socio',
    ICON: 'fa-regular fa-users-between-lines',
    GROUP: 'anagraficaSocio',
    CODPERMESSO: 'ANAG_SOCI',
}

function propsElenco(route: any) {
    return {
        ...route.params,
        dittaId: Number(route.params.dittaId),
        dittaPadreId: Number(route.params.dittaPadreId),
        month: Number(route.params.month),
        year: Number(route.params.year),
    }
}
function propsDettaglio(route: any) {
    return {
        ...route.params,
        day: Number(route.params?.day ?? 1),
        dittaId: Number(route.params.dittaId),
        dittaPadreId: Number(route.params.dittaPadreId),
        id: Number(route.params.id),
        month: Number(route.params.month),
        year: Number(route.params.year),
    }
}
export const SETTINGS = Object.freeze({
    ELENCO: {
        TITLE: 'Elenco soci - Socio - Anagrafici - Gestione',
        LABEL: 'Elenco soci',
        PATH: 'socio/:gestione/:year/:month/:dittaId/:dittaPadreId',
        NAME: 'SocioLista',
        TAB: { ...tabPropsDefault },
    },
    DETTAGLIO: {
        TITLE: 'Dettaglio socio - Socio - Anagrafici - Gestione - Paghe',
        LABEL: 'Dettaglio socio',
        PATH: 'socio/:gestione/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'SocioDettaglio',
        TAB: { ...tabPropsDefault },
    },
    ANAGRAFICA: {
        TITLE: null,
        LABEL: 'Dati anagrafici',
        PATH: 'anagrafica',
        NAME: 'SocioAnagrafica',
        TAB: { ...tabPropsDefault },
    },
})
const routes: Route[] = [
    {
        path: SETTINGS.ELENCO.PATH,
        name: SETTINGS.ELENCO.NAME,
        component: SociViews.Elenco,
        meta: {
            title: SETTINGS.ELENCO.TITLE,
            permissions: {
                codPermesso: tabPropsDefault.CODPERMESSO,
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        props: propsElenco,
    },
    {
        path: SETTINGS.DETTAGLIO.PATH,
        name: SETTINGS.DETTAGLIO.NAME,
        props: propsDettaglio,
        component: SociViews.dettaglio.Dettaglio,
        meta: {
            title: SETTINGS.DETTAGLIO.TITLE,
            permissions: {
                codPermesso: tabPropsDefault.CODPERMESSO,
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        children: [
            {
                label: SETTINGS.ANAGRAFICA.LABEL,
                path: SETTINGS.ANAGRAFICA.PATH,
                name: SETTINGS.ANAGRAFICA.NAME,
                tab: SETTINGS.ANAGRAFICA.TAB,
                orderWizard: 0,
                component: SociViews.dettaglio.childs.Anagrafica,
                meta: {
                    group: 'SocioDettaglio',
                    permissions: {
                        codPermesso: tabPropsDefault.CODPERMESSO,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                props: propsDettaglio,
            },
        ],
    },
]

export default {
    SETTINGS,
    routes,
}
