import ditte from './ditte'
import intermediari from './intermediari'
import status from './status'
import studio from './studio'
import promemoria from './promemoria'
import storico from './storico'
import sommDist from './somm-dist'
import soci from './soci'

export default { ditte, intermediari, status, studio, promemoria, ...storico, sommDist, soci }
