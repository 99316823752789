import { useRoute, useRouter } from 'vue-router'
import { GLOBALS } from '@/constants'
import { APIContratto } from '@/api'
import { type ComputedRef, type Ref, computed, inject, ref } from 'vue'
import { useDiario } from '@/composables/diarioModal'
import { ROUTES_DIPENDENTE } from '@/routes/route-paghe-dipendente'
import { ROUTES_COLLABORATORE } from '@/routes/route-paghe-collaboratore'
import routesSommDist from '@paghe/routes/anagrafiche/somm-dist'
import { NomeTabellaAttivaValue } from '@paghe/entities/ditte'
import { pagheRoutes } from '@/routes'
import { isTableUPActive } from '@paghe/views/anagrafiche/ditte/scripts'
import { cloneDeep } from 'lodash'

export function usePageDitta(pageStore?: any) {
    const router = useRouter()
    const route = useRoute()
    const isInserimento = route.params.mod === GLOBALS.DATA_MODE.INS
    const isModifica = route.params.mod === GLOBALS.DATA_MODE.MOD
    const isInsUP = route.params.mod === GLOBALS.DATA_MODE.INS_UP
    const isFiliale = isInsUP || Number(route.params?.idPadre) > 0 //TODO: da verificare
    const gestUpFiliale: Ref<boolean> = ref(false)

    //ROUTES_DIPENDENTE.DIPENDENTE_LISTA.NAME | ROUTES_COLLABORATORE.COLLABORATORE_LISTA.NAME
    function openList(listName: string, dittaProps: any) {
        router.replace({
            name: listName,
            params: {
                year: dittaProps.year,
                month: dittaProps.month,
                day: 1,
                dittaId: dittaProps.id,
                dittaPadreId: dittaProps.idPadre || 0,
                isAllDitta: dittaProps.isAllDitta,
                isCo: dittaProps.isCo,
            },
        })
    }

    const bottomBarListArchivi: ComputedRef<FooterDropdownMenuItem[] | undefined> = computed(() =>
        pageStore?.onlyEntity
            ? undefined
            : [
                  {
                      title: 'Elenco dipendenti anagrafica ',
                      enabled: route.params.mod === GLOBALS.DATA_MODE.MOD,
                      action: () => openList(ROUTES_DIPENDENTE.DIPENDENTE_LISTA.NAME, route.params),
                  },
                  {
                      title: 'Elenco collaboratori anagrafica',
                      enabled: route.params.mod === GLOBALS.DATA_MODE.MOD,
                      action: () =>
                          openList(ROUTES_COLLABORATORE.COLLABORATORE_LISTA.NAME, route.params),
                  },
                  {
                      title: 'Elenco soci anagrafica',
                      enabled: route.params.mod === GLOBALS.DATA_MODE.MOD,
                      action: () =>
                          openList(pagheRoutes.anagrafiche.soci.SETTINGS.ELENCO.NAME, route.params),
                  },
                  {
                      title: 'Elenco somministrati anagrafica',
                      enabled: route.params.mod === GLOBALS.DATA_MODE.MOD,
                      action: () => openList(routesSommDist.SETTINGS.ELENCO.NAME, route.params),
                  },
                  {
                      title: 'Risultati ditta - dipendente',
                      enabled: route.params.mod === GLOBALS.DATA_MODE.MOD,
                      action: () => {
                          openList(
                              pagheRoutes.risultati.elencoDitte.SETTINGS.DITTA_RISULTATI.NAME,
                              {
                                  ...route.params,
                                  isAllDitta: 1,
                                  isCo: 0,
                              },
                          )
                      },
                  },
                  {
                      title: 'Risultati UP - dipendente ',
                      enabled: route.params.mod === GLOBALS.DATA_MODE.MOD,
                      action: () => {
                          openList(
                              pagheRoutes.risultati.elencoDitte.SETTINGS.DITTA_RISULTATI.NAME,
                              {
                                  ...route.params,
                                  isAllDitta: 0,
                                  isCo: 0,
                              },
                          )
                      },
                  },

                  {
                      title: 'Risultati ditta - collaboratori',
                      enabled: route.params.mod === GLOBALS.DATA_MODE.MOD,
                      action: () =>
                          openList(
                              pagheRoutes.risultati.elencoDitte.SETTINGS.DITTA_RISULTATI.NAME,
                              {
                                  ...route.params,
                                  isAllDitta: 1,
                                  isCo: 1,
                              },
                          ),
                  },
                  {
                      title: 'Risultati UP - collaboratori',
                      enabled: route.params.mod === GLOBALS.DATA_MODE.MOD,
                      action: () =>
                          openList(
                              pagheRoutes.risultati.elencoDitte.SETTINGS.DITTA_RISULTATI.NAME,
                              {
                                  ...route.params,
                                  isAllDitta: 0,
                                  isCo: 1,
                              },
                          ),
                  },
              ],
    )

    const bottomBarListTabelle: ComputedRef<FooterDropdownMenuItem[] | undefined> = computed(() =>
        pageStore?.onlyEntity
            ? undefined
            : [
                  {
                      title: getDittaUPGestione.value?.codAliquota
                          ? `Aliquota - ${getDittaUPGestione.value?.codAliquota}`
                          : 'Aliquota',
                      enabled:
                          route.params.mod === GLOBALS.DATA_MODE.MOD &&
                          !!getDittaUPGestione.value?.codAliquota,
                      action: () => {
                          router.replace({
                              name: pagheRoutes.tabelle.aliquote.SETTINGS.ALIQUOTE.NAME,
                              params: {
                                  year: route.params.year,
                                  month: route.params.month,
                                  codice: getDittaUPGestione.value?.codAliquota,
                                  attualeCheck: 0,
                                  allValidate: 0,
                              },
                          })
                      },
                  },
                  {
                      title: getDittaUPGestione.value?.codContratto
                          ? `Contratto - ${getDittaUPGestione.value?.codContratto}`
                          : 'Contratto',
                      enabled:
                          route.params.mod === GLOBALS.DATA_MODE.MOD &&
                          !!getDittaUPGestione.value?.codContratto,
                      action: () => {
                          router.replace({
                              name: pagheRoutes.tabelle.contratto.SETTINGS.GENERALE.NAME,
                              params: {
                                  year: route.params.year,
                                  month: route.params.month,
                                  codice: getDittaUPGestione.value?.codContratto,
                                  attualeCheck: 0,
                                  allValidate: 0,
                              },
                          })
                      },
                  },
              ],
    )

    //#region DIARIO
    const refDiario = inject('diarioModal', null)
    const isDiario = computed(() => {
        return pageStore?.isDiario ?? false
    })

    const { openDiarioDipte, addDiarioToSavePayload } = useDiario(refDiario)

    //#endregion

    //TODO: TIPIZZARE ENTITA
    // async function getTabellaAttiva(nomeEntita?: string) {

    //     if (isFiliale && nomeEntita) {
    //         const result = await pagheAPI.anagrafiche.ditte.upTabellaAttiva.get({
    //             periodoDiRiferimento: {
    //                 year: +route.params.year,
    //                 month: +route.params.month,
    //             },
    //             nomeEntita,
    //             idDittaList: [+route.params.id],
    //         })

    //         if (result?.data.responseStatus.isSuccessful && result.data.payload?.length) {
    //             return (gestUpFiliale.value = true)
    //         }
    //         return (gestUpFiliale.value = false)
    //     }
    //     return (gestUpFiliale.value = false)
    // }

    async function getTabellaAttiva(nomeEntita?: NomeTabellaAttivaValue) {
        const result = isTableUPActive(pageStore?.state?.data?.dittaUPTabellaAttivaList, nomeEntita)
        gestUpFiliale.value = result
        return result
    }

    function mapBasePayload(payload: any) {
        if (isFiliale) {
            //aggiunto sempre se UP
            const dittaUPAttivazione = {
                isAttiva: gestUpFiliale.value, //valoore della checkbox
                idDitta: +route.params?.idPadre, //idPadre
                idDittaUP: +route.params.id, //idFiglia
            }

            payload = { ...payload, dittaUPAttivazione }
        }

        if (isModifica) {
            payload = addDiarioToSavePayload(payload, null, route.params.id)
        }
        return payload
    }

    //Gestisce se abilitare la GestUP all'interno delle pagine Alterazioni
    const isGestAlterazioneDisabled = computed(() => {
        if (isFiliale && route.params.id) {
            const datiDitta = pageStore?.state?.data?.padre?.dittaUPGestione
            if (!datiDitta) return true

            const datiUP = pageStore?.state?.data?.dittaUPGestione
            return !(!datiUP || datiDitta.codContratto === datiUP?.codContratto)
        }

        return true
    })

    //Ritorna i dati del contratto relativi alla ditta visualizzata nel dettaglio (sede o filiale)
    const getDittaUPGestione = computed(() => {
        if (isFiliale) {
            //inserire tabella attiva ????
            return isTableUPActive(
                pageStore?.state?.data?.dittaUPTabellaAttivaList,
                'Ditta_UPGestione',
            )
                ? pageStore?.state?.data?.dittaUPGestione
                : pageStore?.state?.data?.padre?.dittaUPGestione
        }
        return pageStore?.state?.data?.dittaUPGestione
    })

    async function populateCodici() {
        try {
            const populateCodice = await APIContratto.GetContrattoElencoCodiciElementiPaga(
                getDittaUPGestione.value,
            )
            if (populateCodice.data.responseStatus?.isSuccessful) return populateCodice.data.payload
        } catch (error) {
            console.error(error)
        }
    }

    async function populateCodiciAlterazione() {
        try {
            const populateCodice = await APIContratto.GetAlterazioneElencoCodiciElementiPaga(
                getDittaUPGestione.value,
            )
            if (populateCodice.data.responseStatus?.isSuccessful) return populateCodice.data.payload
        } catch (error) {
            console.error(error)
        }
    }
    async function populateCodiciAlterazioneNoDitta() {
        const data = cloneDeep(getDittaUPGestione.value)
        data.idDitta = null
        try {
            const populateCodice = await APIContratto.GetAlterazioneElencoCodiciElementiPaga(data)
            if (populateCodice.data.responseStatus?.isSuccessful) return populateCodice.data.payload
        } catch (error) {
            console.error(error)
        }
    }

    return {
        bottomBarListArchivi,
        bottomBarListTabelle,
        getDittaUPGestione,
        getTabellaAttiva,
        gestUpFiliale,
        isDiario,
        isFiliale,
        isGestAlterazioneDisabled,
        isInserimento,
        isInsUP,
        isModifica,
        mapBasePayload,
        openDiarioDipte,
        populateCodici,
        populateCodiciAlterazione,
        populateCodiciAlterazioneNoDitta,
    }
}
