import { DipendenteLista } from '@/views/Paghe/Anagrafiche/Dipendente/Lista'
import { DipendenteDettaglio, Childs } from '@/views/Paghe/Anagrafiche/Dipendente/Dettaglio'
import entities from '@paghe/entities'

const tabPropsDefault: TabProps = {
    TITLE: 'Anagrafica Dipendente',
    ICON: 'fa-regular fa-users-between-lines',
    GROUP: 'anagraficaDipendente',
}

export const ROUTES_DIPENDENTE = Object.freeze({
    DIPENDENTE_LISTA: {
        TITLE: 'Elenco dipendenti - Dipendente - Anagrafici - Gestione - Paghe',
        LABEL: 'Elenco dipendenti',
        PATH: 'dipendente/:gestione/:year/:month/:dittaId/:dittaPadreId',
        NAME: 'DipendenteLista',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_DETTAGLIO: {
        TITLE: 'Dettaglio dipendente - Dipendente - Anagrafici - Gestione - Paghe',
        LABEL: 'Dettaglio dipendente',
        PATH: 'dipendente/:gestione/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'DipendenteDettaglio',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ANAGRAFICA: {
        TITLE: null,
        LABEL: 'Dati anagrafici',
        PATH: 'anagrafica',
        NAME: 'DipendenteAnagrafica',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_COLLOCAMENTO: {
        TITLE: null,
        LABEL: 'Collocamento',
        PATH: 'collocamento',
        NAME: 'DipendenteCollocamento',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ENTI: {
        TITLE: null,
        LABEL: 'Enti prev.li/ass.li ',
        PATH: 'enti',
        NAME: 'DipendenteEnti',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_AREA_AZIENDALE: {
        TITLE: null,
        LABEL: 'Area Aziendale',
        PATH: 'areaproduttiva',
        NAME: 'DipendenteAreaAziendale',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_IMPOSTAZIONI_INDIVIDUALI: {
        TITLE: null,
        LABEL: 'Impostazioni individuali ',
        PATH: 'alterazioniDitta',
        NAME: 'DipendenteImpostazioniIndividuali',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_RAPPORTO: {
        TITLE: null,
        LABEL: 'Gestione rapporto',
        PATH: 'rapporto',
        NAME: 'DipendenteRapporto',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_DETRAZIONI: {
        TITLE: null,
        LABEL: 'Detrazioni Fiscali',
        PATH: 'detrazioni',
        NAME: 'DipendenteDetrazioni',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_TFRFPC: {
        TITLE: null,
        LABEL: 'TFR/FPC',
        PATH: 'tfrfpc',
        NAME: 'DipendenteTfrFpc',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_RETRIBUZIONE: {
        TITLE: null,
        LABEL: 'Retribuzione',
        PATH: 'retribuzione',
        NAME: 'DipendenteRetribuzione',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_IMPOSTAZIONI_CEDOLINO: {
        TITLE: null,
        LABEL: 'Impostazioni cedolino 1',
        PATH: 'impostazionicedolino',
        NAME: 'ImpostazioniCedolino',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_IMPOSTAZIONI_CEDOLINO2: {
        TITLE: null,
        LABEL: 'Impostazioni cedolino 2',
        PATH: 'impostazionicedolino2',
        NAME: 'ImpostazioniCedolino2',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_OPZIONI: {
        TITLE: null,
        LABEL: 'Opzioni',
        PATH: 'opzioni',
        NAME: 'DipendenteOpzioni',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_STORICO: {
        TITLE: null,
        LABEL: 'Storico',
        PATH: 'storico',
        NAME: 'DipendenteStorico',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ANF: {
        TITLE: null,
        LABEL: 'ANF',
        PATH: 'anf',
        NAME: 'DipendenteAnf',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_REDDITI_ALTRI_SOGG: {
        TITLE: null,
        LABEL: 'Redditi altri sogg.',
        PATH: 'redditierogati',
        NAME: 'DipendenteRedditiAltriSogg',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_730: {
        TITLE: null,
        LABEL: 'Mod.730',
        PATH: 'mod730',
        NAME: 'Dipendente730',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_SCADENZIARIO: {
        TITLE: null,
        LABEL: 'Scadenziario',
        PATH: 'scadenziario',
        NAME: 'DipendenteScadenziario',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_EREDI: {
        TITLE: null,
        LABEL: 'Eredi',
        PATH: 'eredi',
        NAME: 'DipendenteEredi',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_PRECEDENTI_RAPPORTI: {
        TITLE: null,
        LABEL: 'Prec.rapporti di lavoro',
        PATH: 'PrecRapporto',
        NAME: 'DipendentePrecRapporto',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_DIARIO: {
        TITLE: null,
        LABEL: 'Diario',
        PATH: 'diario',
        NAME: 'DiarioDipendente',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_CAMPIPERSONALIZZATI: {
        TITLE: null,
        LABEL: 'Campi personalizzati',
        PATH: 'campiPersonalizzati',
        NAME: 'DipendenteCampiPersonalizzati',
        TAB: { ...tabPropsDefault },
    },

    DIPENDENTE_CONTRATTOSTRAORDINARIO: {
        TITLE: null,
        LABEL: 'Straordinario /maggiorazioni',
        PATH: 'contrattoStraordinario',
        NAME: 'DipendenteContrattoStraordinario',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_FESTIVITA: {
        TITLE: null,
        LABEL: 'Festività',
        PATH: 'festivita',
        NAME: 'DipendenteAlterazioniFestivita',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ALTERAZIONE_DIVISORI_ORARIO: {
        TITLE: null,
        LABEL: 'Divisori/Orario',
        PATH: 'alterazioneDivisoriOrari',
        NAME: 'DipendenteAlterazioneDivisoriOrari',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ALTERAZIONE_FERIE: {
        TITLE: null,
        LABEL: 'Ferie/Ex fest./Rol',
        PATH: 'alterazioneFerie',
        NAME: 'DipendenteAlterazioneFerie',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ALTERAZIONE_ELEMENTI_PAGA: {
        TITLE: null,
        LABEL: 'Elementi Paga',
        PATH: 'alterazioneElementiPaga',
        NAME: 'DipendenteAlterazioneElementiPaga',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ALTERAZIONE_INFORTUNIO: {
        TITLE: null,
        LABEL: 'Infortunio',
        PATH: 'alterazioneInfortunio',
        NAME: 'DipendenteAlterazioneInfortunio',
        TAB: { ...tabPropsDefault },
    },
    ISTITUTI_CONTRATTUALI: {
        TITLE: null,
        LABEL: 'Istituti Contrattuali',
        PATH: 'IstitutiContrattuali/:codContratto?',
        NAME: 'IstitutiContrattuali',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ALTERAZIONE_MENSILITA_AGGIUNTIVE: {
        TITLE: null,
        LABEL: 'Mensilità aggiuntive',
        PATH: 'dipendenteAlterazioneMensilitaAggiuntive/:codMensilita?',
        NAME: 'DipendenteAlterazioneMensilitaAggiuntive',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_MALATTIA: {
        TITLE: null,
        LABEL: 'Malattia',
        PATH: 'AlterazioneMalattia',
        NAME: 'DipendenteAlterazioneMalattia',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_MATERNITA: {
        TITLE: null,
        LABEL: 'Maternità',
        PATH: 'AlterazioneMaternita',
        NAME: 'DipendenteAlterazioneMaternita',
        TAB: { ...tabPropsDefault },
    },
    DIPENDENTE_ALTERAZIONE_CONTRIBUTIVE: {
        TITLE: null,
        LABEL: 'Contributive',
        PATH: 'AlterazioneContributive',
        NAME: 'DipendenteAlterazioneContributive',
        TAB: { ...tabPropsDefault },
    },
})

export default [
    {
        path: ROUTES_DIPENDENTE.DIPENDENTE_LISTA.PATH,
        name: ROUTES_DIPENDENTE.DIPENDENTE_LISTA.NAME,
        component: DipendenteLista,
        meta: {
            title: ROUTES_DIPENDENTE.DIPENDENTE_LISTA.TITLE,
            permissions: {
                codPermesso: 'ANAG_DIPTE',
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        props: true,
    },
    {
        path: ROUTES_DIPENDENTE.DIPENDENTE_DETTAGLIO.PATH,
        name: ROUTES_DIPENDENTE.DIPENDENTE_DETTAGLIO.NAME,
        props: true,
        component: DipendenteDettaglio,
        meta: {
            title: ROUTES_DIPENDENTE.DIPENDENTE_DETTAGLIO.TITLE,
            permissions: {
                codPermesso: 'ANAG_DIPTE',
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        children: [
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_ANAGRAFICA.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_ANAGRAFICA.NAME,
                component: Childs.DipendenteAnagrafica,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.Anagrafica],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP01',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_COLLOCAMENTO.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_COLLOCAMENTO.NAME,
                component: Childs.DipendenteCollocamento,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.Collocamento],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP02',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_AREA_AZIENDALE.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_AREA_AZIENDALE.NAME,
                component: Childs.DipendenteAreaProduttiva,
                entity: [
                    entities.dipendente.DipendenteEntity.CentroCosto,
                    entities.dipendente.DipendenteEntity.UnitaProduttiva,
                ],
                props: true,
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP03',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_ENTI.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_ENTI.NAME,
                component: Childs.DipendenteEnti,
                props: true,
                entity: [
                    entities.dipendente.DipendenteEntity.Ente,
                    entities.dipendente.DipendenteEntity.EnteAltro,
                ],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP04',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_IMPOSTAZIONI_INDIVIDUALI.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_IMPOSTAZIONI_INDIVIDUALI.NAME,
                component: Childs.DipendenteAlterazioni,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.AlteraDitta],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP05',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_RAPPORTO.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_RAPPORTO.NAME,
                component: Childs.DipendenteRapporto,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.Rapporto],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP06',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_DETRAZIONI.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_DETRAZIONI.NAME,
                component: Childs.DipendenteDetrazioni,
                props: true,
                entity: [
                    entities.dipendente.DipendenteEntity.Detrazione,
                    entities.dipendente.DipendenteEntity.CaricoFamiglia,
                ],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP07',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_TFRFPC.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_TFRFPC.NAME,
                component: Childs.DipendenteTFRFPC,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.TFRFPC],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP08',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_RETRIBUZIONE.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_RETRIBUZIONE.NAME,
                component: Childs.DipendenteRetribuzione,
                props: true,
                entity: [
                    entities.dipendente.DipendenteEntity.Retribuzione,
                    entities.dipendente.DipendenteEntity.RetribElemento,
                ],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP10',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_OPZIONI.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_OPZIONI.NAME,
                component: Childs.DipendenteOpzioni,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.Opzione], //AlteraElePaga ?
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP11',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_IMPOSTAZIONI_CEDOLINO.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_IMPOSTAZIONI_CEDOLINO.NAME,
                component: Childs.DipendenteImpostazioniCedolino1,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.SindENettoC],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP12',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_IMPOSTAZIONI_CEDOLINO2.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_IMPOSTAZIONI_CEDOLINO2.NAME,
                component: Childs.DipendenteImpostazioniCedolino2,
                entity: [
                    entities.dipendente.DipendenteEntity.VoceFissa,
                    entities.dipendente.DipendenteEntity.Prestito,
                    entities.dipendente.DipendenteEntity.EnteFisso,
                    entities.dipendente.DipendenteEntity.NotaCedoFissa,
                ],
                props: true,
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP12',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_STORICO.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_STORICO.NAME,
                component: Childs.DipendenteStorico,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.Storico],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP15',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_ANF.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_ANF.NAME,
                component: Childs.DipendenteAnf,
                props: true,
                entity: [
                    entities.dipendente.DipendenteEntity.ANF,
                    entities.dipendente.DipendenteEntity.ANFConiuge,
                ],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP09',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_REDDITI_ALTRI_SOGG.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_REDDITI_ALTRI_SOGG.NAME,
                component: Childs.DipedenteRedditiErogati,
                props: true,
                entity: [
                    entities.dipendente.DipendenteEntity.AltroRedditoFiscale,
                    entities.dipendente.DipendenteEntity.AltroRedditoPrev,
                ],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP16',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_PRECEDENTI_RAPPORTI.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_PRECEDENTI_RAPPORTI.NAME,
                component: Childs.DipendentePrecRapporto,
                props: true,
                // entity: [
                //     entities.dipendente.DipendenteEntity.,
                //     entities.dipendente.DipendenteEntity.EnteAltro,
                // ],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP17',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_730.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_730.NAME,
                component: Childs.Dipendente730,
                props: true,
                entity: [entities.dipendente.DipendenteEntity[730]],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP13',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_SCADENZIARIO.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_SCADENZIARIO.NAME,
                component: Childs.DipendenteScadenziario,
                props: true,
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP19',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_EREDI.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_EREDI.NAME,
                component: Childs.DipendenteEredi,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.Erede],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP18',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_DIARIO.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_DIARIO.NAME,
                component: Childs.DipendenteDiario,
                props: true,
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP20',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_CAMPIPERSONALIZZATI.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_CAMPIPERSONALIZZATI.NAME,
                component: Childs.DipendenteCampiPersonalizzati,
                props: true,
                entity: [entities.dipendente.DipendenteEntity.CampoPersonalizzato],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP21',
                    permissions: {
                        codPermesso: 'ANAG_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },

            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_CONTRATTOSTRAORDINARIO.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_CONTRATTOSTRAORDINARIO.NAME,
                component: Childs.DipendenteAlterazioneContrattoStraordinario,
                props: true,
                entity: [entities.contratto.ContrattoEntity.Straordinario],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP55',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_FESTIVITA.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_FESTIVITA.NAME,
                component: Childs.DipendenteAlterazioneFestivita,
                props: true,
                entity: [entities.contratto.ContrattoEntity.Festivita],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP53',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_DIVISORI_ORARIO.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_DIVISORI_ORARIO.NAME,
                component: Childs.DipendenteAlterazioneDivisoriOrari,
                props: true,
                entity: [entities.contratto.ContrattoEntity.Divisore],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP54',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.ISTITUTI_CONTRATTUALI.PATH,
                name: ROUTES_DIPENDENTE.ISTITUTI_CONTRATTUALI.NAME,
                component: Childs.IstitutiContrattuali,
                props: true,
                entity: [entities.contratto.ContrattoEntity.IstitutoContr],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP56',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_MENSILITA_AGGIUNTIVE.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_MENSILITA_AGGIUNTIVE.NAME,
                component: Childs.DipendenteAlterazioneMensilitaAggiuntive,
                props: true,
                entity: [entities.contratto.ContrattoEntity.MensAgg],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP57',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_FERIE.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_FERIE.NAME,
                component: Childs.DipendenteAlterazioneFerie,
                props: true,
                entity: [entities.contratto.ContrattoEntity.FeExFestRol],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP58',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_INFORTUNIO.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_INFORTUNIO.NAME,
                component: Childs.DipendenteAlterazioneInfortunio,
                props: true,
                entity: [entities.contratto.ContrattoEntity.Infortunio],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP60',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_MALATTIA.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_MALATTIA.NAME,
                component: Childs.DipendenteAlterazioneMalattia,
                props: true,
                entity: [entities.contratto.ContrattoEntity.Malattia],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP59',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_MATERNITA.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_MATERNITA.NAME,
                component: Childs.DipendenteAlterazioneMaternita,
                props: true,
                entity: [entities.contratto.ContrattoEntity.Maternita],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP61',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_CONTRIBUTIVE.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_CONTRIBUTIVE.NAME,
                component: Childs.DipendenteAlterazioneContributive,
                props: true,
                entity: [entities.contratto.ContrattoEntity.Ente],
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP62',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
            {
                path: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_ELEMENTI_PAGA.PATH,
                name: ROUTES_DIPENDENTE.DIPENDENTE_ALTERAZIONE_ELEMENTI_PAGA.NAME,
                component: Childs.DipendenteAlterazioneElementiPaga,
                entity: [entities.altera.AlteraEntity.ElePaga],
                props: true,
                meta: {
                    group: 'DipendenteDettaglio',
                    idPaginaMsg: 'DP50',
                    permissions: {
                        codPermesso: 'ALTERA_DIPTE',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
            },
        ],
    },
]
