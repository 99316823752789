enum STORES_NAMES {
    COMUNI = 'comuniStore',
    DIPENDENTE = 'dipendenteStore',
    DIPENDENTE_LIST = 'dipendenteListStore',
    DITTE = 'ditteStore',
    DITTE_LIST = 'ditteListStore',
    EXPORT = 'exportStore',
    EXPORT_DEF = 'exportDefStore',
    GLOBAL = 'globalStore',
    INTERMEDIARIO = 'intermediarioStore',
    LOOKUP = 'lookup',
    OPERATORE = 'operatoreStore',
    OPERATORE_LIST = 'listaOperatoreStore',
    SEGRETERIA = 'segreteriaStore',
    STUDIO = 'studioStore',
    RISULTATI_DP = 'RisultatiDPStore',
    TAB = 'tabStore',
}

export default STORES_NAMES
