import dipendenti from './dipendenti'
import ditte from './ditte'
import intermediari from './intermediari'
import status from './status'
import studio from './studio'
import promemoria from './promemoria'
import storico from './storico'
import sommDist from './somm-dist'
import soci from './soci'
export default {
    dipendenti,
    ditte,
    intermediari,
    promemoria,
    status,
    studio,
    storico,
    sommDist,
    soci,
}
